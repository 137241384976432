var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-5"},[_c('b-card',{staticClass:"home-wrapper"},[_c('b-row',[_c('b-col',{staticClass:"text-right mb-3 d-flex justify-content-end"},[[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('rolePermission'),expression:"'rolePermission'"}],staticClass:"btn-color",attrs:{"variant":"primary","data-testid":"add-new-role"},on:{"click":function($event){return _vm.addNewRole()}}},[_vm._v(" + Add A Role ")])],(_vm.openModal && _vm.btnOperation=='Add')?[_c('role-permission',{staticClass:"modal-body",attrs:{"title":"Add A Role Level","permissionRoleData":_vm.userRolePermissions,"show-modal":_vm.showModal,"hide-modal":_vm.hideModal,"save-details":_vm.saveDetails,"btn-operation":_vm.btnOperation},on:{"isLoading":_vm.loader}})]:_vm._e()],2)],1),_c('div',[(_vm.userRole.length > 0)?_c('b-table',{attrs:{"small":"","fields":_vm.fields,"items":_vm.userRole,"responsive":"sm","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(roleName)",fn:function(userRoleObj){return [_c('div',{staticClass:"font-color"},[_vm._v(_vm._s(userRoleObj.item.roleName)+" ")])]}},{key:"cell(description)",fn:function(userRoleObj){return [_c('div',{staticClass:"font-color"},[_vm._v(_vm._s(userRoleObj.item.description))])]}},{key:"cell(permissions)",fn:function(userRoleObj){return [_vm._l((userRoleObj.item.permissions),function(item){return [(_vm.userPermission(item)!='')?_c('b-badge',{staticClass:"badge-spacing",attrs:{"pill":""}},[_vm._v(" "+_vm._s(_vm.userPermission(item))+" ")]):_vm._e()]})]}},{key:"cell(view)",fn:function(ref){
var userRoleObj = ref.item;
return [_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('rolePermission'),expression:"'rolePermission'"}],staticClass:"btn-styling",attrs:{"data-testid":'view-role'+userRoleObj.id},on:{"click":function($event){return _vm.viewRole(userRoleObj)}}},[_c('b-icon-eye')],1),(_vm.openModal && _vm.btnOperation=='view')?[_c('role-permission',{staticClass:"modal-body",attrs:{"title":"","permissionRoleData":_vm.viewData,"show-modal":_vm.showModal,"hide-modal":_vm.hideModal,"save-details":_vm.saveDetails,"btn-operation":_vm.btnOperation},on:{"isLoading":_vm.loader}})]:_vm._e()]}},{key:"cell(edit)",fn:function(ref){
var userRoleObj = ref.item;
return [_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('rolePermission'),expression:"'rolePermission'"}],staticClass:"btn-styling",attrs:{"disabled":_vm.nonEditableRoles(userRoleObj.roleName),"data-testid":'edit-role'+userRoleObj.id},on:{"click":function($event){return _vm.editRole(userRoleObj)}}},[_c('b-icon-pencil-square')],1),(_vm.openModal && _vm.btnOperation=='Edit')?[_c('role-permission',{staticClass:"modal-body",attrs:{"title":"Edit A Role Level","permissionRoleData":_vm.editData,"show-modal":_vm.showModal,"hide-modal":_vm.hideModal,"save-details":_vm.saveDetails,"btn-operation":_vm.btnOperation},on:{"isLoading":_vm.loader}})]:_vm._e()]}},{key:"cell(delete)",fn:function(ref){
var userRoleObj = ref.item;
return [_c('button',{staticClass:"btn-styling",attrs:{"disabled":_vm.nonEditableRoles(userRoleObj.roleName),"data-testid":'delete-role'+userRoleObj.id},on:{"click":function($event){return _vm.deleteRole(userRoleObj)}}},[_c('b-icon-trash')],1),_c('b-modal',{on:{"hide":_vm.hideModal,"ok":function($event){return _vm.deleteRole()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [[_c('b-button',{attrs:{"size":"sm","variant":"pastel-blue","data-testid":"cancel-button"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary","data-testid":"delete-button"},on:{"click":function($event){return _vm.deleteUserRole(userRoleObj)}}},[_vm._v(" Delete ")])]]}}],null,true),model:{value:(_vm.deleteModalShow),callback:function ($$v) {_vm.deleteModalShow=$$v},expression:"deleteModalShow"}},[_c('div',{staticClass:"d-block"},[_vm._v("Would you like to delete the role?")])])]}}],null,false,2778626096)}):_c('b-skeleton-table',{attrs:{"rows":6,"columns":3}}),(_vm.showToast)?_c('b-toast',{attrs:{"no-fade":true,"append-to-body":true}}):_vm._e()],1),[(_vm.loading)?_c('div',{staticClass:"\n                        text-center\n                        w-100\n                        d-flex\n                        justify-content-center\n                        align-items-center\n                    ",staticStyle:{"height":"200px"}},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"Loading..."}})],1):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }