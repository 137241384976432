<script>

import { BSpinner, BIconChevronDown, BIcon } from 'bootstrap-vue';

export default {
	name: 'RolePermission',
	components: {
		BSpinner,
    BIconChevronDown,
    BIcon
	},
	data() {
		return {
			loading: false,
            permissionData: this.permissionRoleData,
            selected:[],
            fields: [
                { key: 'view', label:'View'},
                { key: 'write', label:'Write'},
                { key: 'delete', label:'Delete'},
                { key: 'name', label:"Name", sortable: false },
                { key: 'description', label:"Description", sortable: false },
                
            ],
            addingRole:false,
            error:'',
            message:'',
            showModal: this.openModal,
		};
	},
	props:['openModal', 'hideModal','title', 'btnOperation', 'permissionRoleData', 'userRolePermissions'],
	
	methods: {
        create(){
          // let filteredPermissionData = this.permissionData.permissions.filter(permission => {
          // if (permission.hasAddPermission || permission.hasViewPermission || permission.hasEditPermission || permission.hasDeletePermission) {
          //   if (permission.options) {
          //     permission.options = permission.options.filter(option => option.hasAddPermission|| option.hasViewPermission|| option.hasEditPermission || option.hasDeletePermission);
          //     return permission.options.length > 0;
          //   }
          //   return true;
          // }
          // return false;
          // });
          // this.permissionData.permissions = filteredPermissionData
            this.$store.dispatch('addUserRole', this.permissionData)
            .then(() => {
              this.showModal = false;
              this.showToastMessage('Role created successfully!', 'success');
              // this.$store.dispatch('getUserRole')
              window.location.reload()
            })
            .catch(error => {
              console.error('Error creating role:', error);
              this.showToastMessage('Error creating role. Please try again.', 'danger');
            });
        },
        edit(){
            this.$store.dispatch('updateUserRole', this.permissionData)
            .then(() => {
              this.showModal = false;
              this.showToastMessage('Role updated successfully!', 'success');
              this.$store.dispatch('getUserRole')
            })
            .catch(error => {
              console.error('Error updating role:', error);
              this.showToastMessage('Error updating role. Please try again.', 'danger');
            });
        },
        functionSelection(){
          if(this.btnOperation=="Add"){
              this.create()
          }
          if(this.btnOperation=="Edit"){
            this.edit()
          }
        },
        close(){
          window.location.reload()
        },
        showToastMessage(message, variant) {
          this.$bvToast.toast(message, {
            title: '',
            autoHideDelay: 5000,
            appendToast: true,
            variant: variant,
          });
      },
      handleBuilderEditPermissionChange(builderItem) {
        const builderOptionEditPermission = builderItem.hasEditPermission;

        if (builderItem.options) {
          builderItem.options.forEach(option => {
            option.hasEditPermission = builderOptionEditPermission;
          });
        }
      },
      toggleNestedTable(index) {
      this.$set(this.permissionData.permissions, index, {
        ...this.permissionData.permissions[index],
        showNestedTable: !this.permissionData.permissions[index].showNestedTable,
      });
    },
    },
	mounted(){
		this.btnOperation = this.btnOperation
	}
};
</script>

<template>
	<b-modal
		id="rolePermission"
		v-model="showModal"
		:title="title"
    :userRolePermissions="permissionRoleData"
    :btnOperation="btnOperation"
		@hide="hideModal"
    size="xl"
    scrollable
	>
		<template #modal-footer="{ ok, cancel }">
      <template v-if="btnOperation !== 'view'">
        <b-button  size="sm" variant="navy" @click="close()" data-testid="cancel-button"> Cancel </b-button>
        <b-button  size="sm" variant="primary" @click="functionSelection()" data-testid="save-button">
          <template v-if="btnOperation=='Edit'">Save</template>
          <template v-else>{{ btnOperation }}</template>
        </b-button>
      </template>
		</template>
    <b-container fluid>
            <div class="bottom-spacing"><b>Name:</b> <b-form-input v-model="permissionData.roleName" data-testid="user-role-name" placeholder="Role Name" :readOnly="btnOperation=='view'" required></b-form-input></div>
            <div class="bottom-spacing"><b>Description:</b> <b-form-input v-model="permissionData.description" data-testid="user-role-description" placeholder="Description" :readOnly="btnOperation=='view'" >
            </b-form-input></div>
            <div class="form">
          <div class="bottom-spacing form-select">
            <b>Roles associated to:</b>
              <b-form-select
                id="roles-associated-to"
                v-model="permissionData.roleAssociated"
                :options="['lawyer','party','assistant']"
                size="sm"
                :disabled="btnOperation=='view'"
                required
                data-testid="user-roles-associated"
              ></b-form-select>
        </div>
          <!-- <div class="bottom-spacing form-checkbox">
            <b>Is role associated per agreement?</b>
              <b-form-checkbox-group
                v-model="permissionData.roleAssociatedPerAgreement"
                class="mt-1"
                :disabled="btnOperation=='view'"
                required
              >
                <b-form-checkbox value="true"  class="size">Yes</b-form-checkbox>
                <b-form-checkbox value="false" class="size">No</b-form-checkbox>
              </b-form-checkbox-group>
        </div> -->
      </div>
        <!-- </b-row> -->
    <div class="bottom-spacing">
        <div><b>Permissions:</b></div>
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                        <th>Add</th>
                        <th>View</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Name</th>
                        <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in permissionData.permissions">
                  <!-- <template v-for="permission in rolePermissionData.permissions"> -->
                  <tr data-testid="toggle" style="cursor: pointer;">
                    <td> 
                      <label class="form-checkbox">
                          <input v-if="item.value =='agreements'|| item.value =='property-schedule'" :data-testid="'add-permission-'+item.value" type="checkbox" v-model="item.hasAddPermission" :disabled="btnOperation=='view'">
                          <i class="form-icon"></i>
                        </label>
                    </td>
                    <td> 
                        <label class="form-checkbox">
                            <input v-if="item.value!='agreements' && item.value!='builder'" type="checkbox" :data-testid="'view-permission-'+item.value" v-model="item.hasViewPermission" :disabled="btnOperation=='view'">
                            <i class="form-icon"></i>
                          </label>
                    </td>
                    <td>
                        <label class="form-checkbox">
                            <input type="checkbox" v-if="item.value!='preview'" :data-testid="'edit-permission-'+item.value" v-model="item.hasEditPermission" :disabled="btnOperation=='view'"
                            @change="handleBuilderEditPermissionChange(item)">
                            <i class="form-icon"></i>
                          </label>
                    </td>
                    <td>
                        <label class="form-checkbox">
                            <input v-if="item.value=='agreements'" type="checkbox" :data-testid="'delete-permission-'+item.value" v-model="item.hasDeletePermission" :disabled="btnOperation=='view'">
                            <i class="form-icon"></i>
                          </label>
                    </td>
                    <td>{{item.text}}</td>
                    <td>{{item.description}}
                    </td>
                    <td>
                      <span v-if="item.options" :data-testid="'drop-down-'+item.value" class="dropdown-icon" @click="toggleNestedTable(index)">
                        <b-icon icon="chevron-down" :class="item.showNestedTable ? '': 'icon-rotated'"></b-icon>
                      </span>
                    </td>
                  </tr>
                    <template v-if="item.options && item.showNestedTable">
                    <tr class="bg">
                      <td colspan="7">
                        <table class="table nested-table">
                          <colgroup>
                            <col v-for="(col, index) in fields" :style="{ width: index === 4 ? '27%' : '6.3%' }" :key="col.key"/>
                          </colgroup>
                          <tbody>
                            <tr v-for="option in item.options">
                              <td>
                                <label class="form-checkbox">
                                  <input type="checkbox" v-if="option.value=='share-agreement'" :data-testid="'add-permission-'+option.value" v-model="option.hasAddPermission" :disabled="btnOperation === 'view'">
                                  <i class="form-icon"></i>
                                </label>
                              </td>
                              <td>
                                <label class="form-checkbox">
                                  <input type="checkbox" v-model="option.hasViewPermission" :data-testid="'view-permission-'+option.value" :disabled="btnOperation === 'view'">
                                  <i class="form-icon"></i>
                                </label>
                              </td>
                              <td>
                                <label class="form-checkbox">
                                  <input type="checkbox" v-if="option.value!='share-agreement'" :data-testid="'edit-permission-'+option.value" v-model="option.hasEditPermission" :disabled="btnOperation === 'view'">
                                  <i class="form-icon"></i>
                                </label>
                              </td>
                              <td>
                                <label class="form-checkbox">
                                  <input type="checkbox" v-if="option.value=='share-agreement'" :data-testid="'delete-permission-'+option.value"
                                  v-model="option.hasDeletePermission" :disabled="btnOperation === 'view'">
                                  <i class="form-icon"></i>
                                </label>
                              </td>
                              <td>{{ option.text }}</td>
                              <td>{{ option.description }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </template>
                </template>
            </tbody>
        </table>
      </div>
    </b-container>
	</b-modal>
</template>
<style>
.bottom-spacing{
    padding-bottom: 8px !important;
}
.form {
  display: flex;
  width: 100vh;
}

.form-select,
.form-checkbox {
  flex: 1;
}

.form-select {
  padding-right: 50px;
}

.nested-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  
}
.nested-table th, .nested-table td {
  padding: 6px;
  background-color: white;
}
.bg {
  background-color: #c8c9ca !important;
}
.nested-table th {
  background-color: #000;
  color: white;
}

input[type=checkbox] {
  transform: scale(1.3);
}

.size{
  transform: scale(1.1);
}

.dropdown-icon {
  cursor: pointer;
  display: inline-block;
}
.right-spacing{
  padding-right:2rem !important;
}
.icon-rotated {
  transform: rotate(180deg);
}
</style>